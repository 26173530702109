<template>
  <v-dialog v-model="dialogOpen" persistent max-width="750px">
    <template v-if="0" v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Open Dialog
      </v-btn>
    </template>
    <v-card height="85vh" v-if="subscription">
      <v-card-title>
        <span class="headline" :title="subscription && subscription._id">Prenumeration</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-subheader>ARTIKLAR</v-subheader>
              <v-list dense>
                <v-list-item-group v-model="selectedArticle" color="primary">
                  <v-list-item v-for="(item, i) in articles" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>

            <v-col cols="12" sm="12" md="8">
              <v-form
                :disabled="selectedArticle < 0"
                ref="form"
                v-model="valid"
              >
                <v-subheader>PRENUMERATION</v-subheader>

                <v-select
                  :items="computedPrices"
                  v-model="subscription.articleId"
                  label="Prenumerationstyp"
                  no-data-text="Inga prenumerationstyper hittades"
                  item-value="_id"
                  item-text="description"
                  dense
                  required
                >
                  <template v-slot:item="data">
                    <div class="d-flex flex-row flex justify-space-between">
                      <div>
                        {{ `${data.item.description} ${data.item.price} kr` }}
                      </div>
                      <div>({{ `${data.item.months} mån` }})</div>
                    </div>
                  </template>
                </v-select>

                <DatePickerMenu
                  class="mt"
                  v-model="subscription.startDate"
                  label="Prenumerationen startar"
                  hint="(alltid den första)"
                  returnFormat="YYYY-MM-01T00:00:00.000Z"
                  displayFormat="YYYY-MM"
                  @input="setEndDate"
                />

                <DatePickerMenu
                  v-model="subscription.endDate"
                  label="Prenumerationen slutar"
                  hint="(alltid den siste)"
                  returnFormat="YYYY-MM-DDT23:59:59.999Z"
                  displayFormat="YYYY-MM"
                />

                <v-text-field
                  v-model="subscription.ex"
                  type="number"
                  label="Antal exemplar"
                  class="pa-0 mt-6"
                />

                <v-checkbox
                  v-model="subscription.renewAuto"
                  :label="`Förnya automatiskt`"
                ></v-checkbox>

                <v-autocomplete
                  v-model="subscription.personId"
                  :items="personsList"
                  item-value="_id"
                  label="Prenumerant"
                  placeholder="Aktuell person"
                  :filter="getList"
                  clearable
                >
                  <template v-slot:selection="data">
                    <template v-if="data.item.type === 1">
                      <v-list-item-content>{{
                        `${data.item.id} ${data.item.lastname} ${data.item.firstname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                    <template v-if="data.item.type === 2">
                      <v-list-item-content>{{
                        `${data.item.id}, ${data.item.lastname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="data.item.type === 1">
                      <v-list-item-content>{{
                        `${data.item.id} ${data.item.lastname} ${data.item.firstname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                    <template v-if="data.item.type === 2">
                      <v-list-item-content>{{
                        `${data.item.id}, ${data.item.lastname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                  v-model="subscription.payerId"
                  :items="personsList"
                  item-value="_id"
                  label="Betalare"
                  placeholder="Aktuell person"
                  :filter="getList"
                  clearable
                >
                  <template v-slot:selection="data">
                    <template v-if="data.item.type === 1">
                      <v-list-item-content>{{
                        `${data.item.id}, ${data.item.lastname} ${data.item.firstname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                    <template v-if="data.item.type === 2">
                      <v-list-item-content>{{
                        `${data.item.id}, ${data.item.lastname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="data.item.type === 1">
                      <v-list-item-content>{{
                        `${data.item.id}, ${data.item.lastname} ${data.item.firstname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                    <template v-if="data.item.type === 2">
                      <v-list-item-content>{{
                        `${data.item.id}, ${data.item.lastname}, ${data.item.postal_address}`
                      }}</v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialogOpen = false">
          Stäng
        </v-btn>
        <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
          Spara
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { models } from 'feathers-vuex'
import DatePickerMenu from '@/components/DatePickerMenu'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    DatePickerMenu,
  },
  data: () => ({
    loading: true,
    selectedArticle: -1,
    articles: [],
    prices: [],
    price: null,
    personsList: [],
    valid: false,
    tab: null,
    nameRules: [
      v => !!v || 'Namn är obligatoriskt',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    emailRules: [
      v => !!v || 'E-post är obligatoriskt',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    items: [
      { tab: 'Personuppgifter', content: 'Tab 1 Content' },
      { tab: 'Prenumerationer', content: 'Tab 2 Content' },
      { tab: 'Historik', content: 'Tab 3 Content' },
    ],
  }),
  async mounted() {
    await this.fetchArticles()
    await this.fetchPersons()
    await this.fetchSubscriptionPrice()
  },
  methods: {
    close() {
      this.dialogOpen = false
    },
    async save() {
      if (!this.subscription.personId)
        this.subscription.personId = this.$store.state.dialogPerson._id

      if (!this.subscription.payerId)
        this.subscription.payerId = this.$store.state.dialogPerson._id

      const subscription = new models.api.Subscription(this.subscription)

      if (this.subscription._id) {
        const item = await subscription.update()
        this.subscription = { ...item }
        this.$emit('subscriptionUpdated', item)
      } else {
        const item = await subscription.save()
        this.subscription = { ...item }
        this.$emit('subscriptionUpdated', item)
      }

      const subscribtionTitle = `${this.computedArticle.name} ${this.subscription.description}`

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 3500,
        text: `Prenumeration på ${subscribtionTitle} tillagd`,
        visible: true,
      })

      this.close()
    },
    getList(item, queryText) {
      const search = queryText
        .toLowerCase()
        .split(' ')
        .filter(o => o)
      let count = []
      Object.keys(item).forEach(key => {
        if (typeof item[key] === 'string' || typeof item[key] === 'number') {
          const string = '' + item[key]
          const index = search.findIndex(
            s =>
              string
                .trim()
                .toLowerCase()
                .indexOf(s) > -1
          )

          if (index > -1 && !count.includes(index)) {
            count.push(index)
          }
        }
      })
      return count.length === search.length
    },
    setEndDate(date) {
      let endDate
      endDate = moment
        .utc(date)
        .add(1, 'y')
        .add(-1, 'd')
        .format('YYYY-MM-DDT23:59:59.999Z')
      if (this.selectedArticleIsWholeYear)
        endDate = moment
          .utc(date)
          .add(1, 'y')
          .add(-1, 'd')
          .format('YYYY-MM-DDT23:59:59.999Z')
      if (this.computedPrice)
        endDate = moment
          .utc(date)
          .add(this.computedPrice.months, 'months')
          .add(-1, 'd')
          .format('YYYY-MM-DDT23:59:59.999Z')

      this.subscription.endDate = endDate
    },
    async fetchSubscriptionPrice() {
      const sub = this.subscription

      if (sub && sub.articleId) {
        await this.fetchPrice(sub.articleId)

        const article = this.articles.findIndex(
          a => a._id === this.price.articleId
        )
        this.selectedArticle = article
      }
    },

    async fetchPersons() {
      const { Person } = models.api

      const data = await Person.find({
        query: { active: true },
      })

      this.personsList = this.multiSortArray(data, {
        lastname: 'asc',
        firstname: 'asc',
      })
    },
    async fetchArticles() {
      const { Article } = models.api

      const data = await Article.find()

      this.articles = data
    },

    async fetchPrices() {
      const { Price } = models.api

      if(this.selectedArticle < 0)
        return

      const articleId = this.articles[this.selectedArticle]._id

      const data = await Price.find({
        query: {
          articleId,
          personType: { $in: [this.$store.state.dialogPerson.type] },
          active: true
        },
      })

      /* const groupByHighest = Array.from([...data].reduce((m, obj) => {
        const curr = m.get(obj.price);
        return m.set(obj.description, curr ? (curr.pricePeriodStart < obj.pricePeriodStart ? obj : curr) : obj);
      }, new Map).values()); */

      // console.log(groupByHighest)
      this.prices = data.sort((a, b) => a.description - b.description)

      /* this.prices = groupByHighest.map(price => {
        return {
          ...price,
        }
      }) */
    },
    async fetchPrice(_id = '') {
      const { Price } = models.api

      const data = await Price.find({
        query: {
          _id,
        },
      })

      this.price = data[0]
    },
  },
  computed: {
    dialogOpen: {
      get() {
        return this.$store.state.dialogSubscriptionOpen
      },
      set(bool) {
        this.$store.state.dialogSubscriptionOpen = bool
      },
    },
    subscription: {
      get() {
        return this.$store.state.dialogSubscription
      },
      set(bool) {
        this.$store.state.dialogSubscription = bool
      },
    },
    computedArticle() {
      if (this.selectedArticle < 0) return {}

      return this.articles[this.selectedArticle]
    },
    computedPrice() {
      if (!this.subscription || !this.subscription.articleId) return null

      return this.computedPrices.find(p => p._id == this.subscription.articleId)
    },

    computedPrices() {
      let prices = []
      const priceList = _.orderBy(this.prices, ['pricePeriodStart'], ['desc'])
      const startTime = this.subscription && this.subscription.startDate;
      //const articleId = this.subscription && this.subscription.articleId;
      // console.log(priceList, startTime)
      // console.log(articleId)
      if(this.computedArticle && startTime)
        priceList.forEach(price => {
          // console.log(price._id, articleId, price._id === articleId)
          //price.description += " " + price.id
          if(!prices.find(p => p.description === price.description) && new Date(price.pricePeriodStart).getTime() <= new Date(startTime).getTime())
            prices.push(price)
        })
      else
        prices = []

      prices = _.orderBy(prices, ['description'], ['asc'])

      // console.log(prices)

      return prices
    },
    selectedArticleId() {
      if (!this.subscription) return ''
      return this.subscription.articleId
    },
    selectedArticleIsWholeYear() {
      if (!this.subscription || !this.subscription.articleId) return false

      const price = this.computedPrices.find(
        o => o._id === this.selectedArticleId
      )
      if (!price) return false
      return price.wholeYear
    },
  },
  watch: {
    selectedArticleId() {
      if (this.computedPrice) {
        let endDate = moment
          .utc(this.subscription.startDate)
          .add(this.computedPrice.months, 'months')
          .add(-1, 'd')
          .format('YYYY-MM-DDT23:59:59.999Z')

        console.log(endDate)

        this.subscription.price = this.computedPrice.price
        this.subscription.endDate = endDate
      }
    },
    selectedArticle() {
      this.fetchPrices()
    },
    async subscription(sub) {
      if (!sub) return

      await this.fetchSubscriptionPrice()
      //this.selectedArticle = this.articles.find()
    },
    computedArticle(a) {
      if(!a._id) return

      this.fetchPrices()

    },
    async dialogOpen(bool) {
      if (bool) {
        if (!this.subscription) {
          const start = moment
            .utc()
            .startOf('month')
            .format('Y-MM-DDT00:00:00.000Z')
          const end = moment.utc().format('Y-MM-DDT23:59:59.999Z')
          this.subscription = {
            articleId: '',
            personId: '',
            payerId: '',
            invoiceRowId: '',
            pdf: false,
            ex: 1,
            price: 0,
            startDate: start,
            endDate: end,
            betaltDatum: '',
            renewed: false,
            renewAuto: true,
            renewedId: '',
            extraNumber: false,
            status: 'active',
          }
        }
        else {
          await this.fetchPrices()
        }
      } else {
        setTimeout(() => {
          this.subscription = null
          this.selectedArticle = -1
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss">
.v-select__slot {
  input {
    position: absolute;
  }
}
</style>
